import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Card, CardBody, CardTitle, CardSubtitle, CardText, Container} from 'reactstrap';

const PostDetail = () => {
    const {postId} = useParams();
    const [post,
        setPost] = useState(null);
    const [error,
        setError] = useState(false);
    const API = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchPost = async() => {
            try {
                const response = await fetch(`${API}/post/${postId}/`);
                if (response.ok) {
                    const data = await response.json();
                    setPost(data);
                } else {
                    setError(true);
                }
            } catch (error) {
                console.error('Error fetching post:', error);
                setError(true);
            }
        };

        fetchPost();
    }, [API, postId]);


    return (
        <Container>
            {error ? (
                <p>Error fetching post.</p>
            ) : post ? (
                <Card className="px-2 px-lg-3 row gx-4 gx-lg-5">
                    <CardBody className='bg-light'>
                        <CardTitle className='h1 rounded border-bottom border-dark' style={{ height: '55px' }}>{post.title}</CardTitle>
                        <CardSubtitle className='h6 my-3'>
                            <p>By {post.author && (post.author.first_name + ' ' + post.author.last_name || post.author.username)}</p>
                        </CardSubtitle>

                        <CardText>
                            <p dangerouslySetInnerHTML={{ __html: post.post }}></p>
                        </CardText>
                    </CardBody>
                </Card>
            ) : (
                <p>Loading...</p>
            )}
        </Container>
    );
};

export default PostDetail;